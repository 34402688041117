/**
* Template Name: Green - v4.9.1
* Template URL: https://bootstrapmade.com/green-free-one-page-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import url(swiper-bundle.min.css);
:root {
  --primary : #5067da;
  --violet:#b91be0;
}

body {
  color: #2e2e2e;
}

a {
  text-decoration: none;
  color: var(--primary);
}

a:hover {
  color: var(--violet);
  text-decoration: none;
}

#btnComp{
  transition: ease-out 0.3s;
  border-radius: 4px;
  font-size: 14px;
}



/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-in-out;
  width: 100%;
  height: 100vh;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid var(--primary);
  border-radius: 50%;
  -webkit-animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}





/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #5cb874;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #78c48c;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background: #fbfbfb;
  font-size: 15px;
  height: 25px;
  padding: 0;
}

#topbar .contact-info a {
  line-height: 0;
  color: #444444;
  transition: 0.3s;
}

#topbar .contact-info a:hover {
  color: #5cb874;
}

#topbar .contact-info i {
  color: var(--primary);
  line-height: 0;
  margin-right: 5px;
}

#topbar .contact-info .phone-icon {
  margin-left: 15px;
}

#topbar .social-links a {
  color: #6f6f6f;
  padding: 4px 12px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
}

#topbar .social-links a:hover {
  color:var(--primary);
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  height: 80px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

#header.header-scrolled {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo {
  font-size: 20px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

#header .logo a {
  color: var(--primary);
}

#header .logo img {
  max-height: 40px;
}

.scrolled-offset {
  margin-top: 70px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  color: #252525;
  white-space: nowrap;
  transition: 0.2s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: var(--primary);
}

.navbar .getstarted,
.navbar .getstarted:focus {
  color: var(--primary);
  padding: 8px 25px;
  margin-left: 30px;
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: 0.3s;
  font-size: 14px;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  background: var(--primary);
  color: #fff;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s ease-out;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  text-transform: none;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: var(--primary);
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #222222;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(9, 9, 9, 0.9);
  transition: 0.3s;
  z-index: 998;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #222222;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: var(--primary);
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: var(--primary);
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 70vh;
  background-color: rgba(9, 9, 9, 0.8);
  overflow: hidden;
  position: relative;
}

#hero .carousel,
#hero .carousel-inner,
#hero .carousel-item,
#hero .carousel-item::before {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-item::before {
  content: "";
  background-color: rgba(51, 64, 122, 0.596);
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 50px;
  right: 50px;
}

#hero .container {
  text-align: center;
}

#hero h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: 700;
}

#hero p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 30px;
  line-height: 0;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.5);
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hero .carousel-control-next-icon:hover,
#hero .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

#hero .carousel-indicators li {
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: 0.6;
  transition: 0.3s;
}

#hero .carousel-indicators li.active {
  opacity: 1;
  background: #5cb874;
}

#hero .btn-get-started {
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: var(--primary);
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: #fff;
}

#hero .btn-get-started:hover {
  background: var(--primary);
  color: #fff;

}

@media (max-width: 992px) {
  #hero {
    height: 100vh;
  }

  #hero .carousel-container {
    top: 66px;
  }
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 28px;
  }
}

@media (min-width: 1024px) {

  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}




/*--------------------------------------------------------------
# Alt Hero
---------------------------------------------------------------*/

#althero {
  width: 100%;
  height: 50vh;
  background-color: rgba(9, 9, 9, 0.8);
  overflow: hidden;
  position: relative;
}

#althero .carousel,
#althero .carousel-inner,
#althero .carousel-item,
#althero .carousel-item::before {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#althero .carousel-item::before {
  content: "";
  background-color: rgba(51, 64, 122, 0.596);
}

#althero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 50px;
  right: 50px;
}

#althero .container {
  text-align: center;
}

#althero h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: 700;
}

#althero p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

#althero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center;
}

#althero .carousel-inner .carousel-item,
#althero .carousel-inner .active.carousel-item-start,
#althero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#althero .carousel-inner .active,
#althero .carousel-inner .carousel-item-next.carousel-item-start,
#althero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#althero .carousel-control-next-icon,
#althero .carousel-control-prev-icon {
  background: none;
  font-size: 30px;
  line-height: 0;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.5);
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#althero .carousel-control-next-icon:hover,
#althero .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

#althero .carousel-indicators li {
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: 0.6;
  transition: 0.3s;
}

#althero .carousel-indicators li.active {
  opacity: 1;
  background: #5cb874;
}

#althero .btn-get-started {
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: var(--primary);
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: #fff;
}

#althero .btn-get-started:hover {
  background: var(--primary);
  color: #fff;

}

@media (max-width: 992px) {
  #althero {
    height: 40vh;
  }

  #althero .carousel-container {
    top: 66px;
  }
}

@media (max-width: 768px) {
  #althero h2 {
    font-size: 28px;
  }
}

@media (min-width: 1024px) {

  #althero .carousel-control-prev,
  #althero .carousel-control-next {
    width: 5%;
  }
}

@media (max-height: 500px) {
  #althero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: #f8fcf9;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: var(--primary);
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
  font-size: 14px;
  color: #919191;
}

/*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/
.featured-services {
  padding: 20px 0;
}

.featured-services .icon-box {
  padding: 20px;
}

.featured-services .icon-box-bg {
  background-image: linear-gradient(0deg, #222222 0%, #2f2f2f 50%, #222222 100%);
}

.featured-services .icon {
  margin-bottom: 15px;
}

.featured-services .icon i {
  color: var(--primary);
  font-size: 42px;
}

.featured-services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.featured-services .title a {
  color: #222222;
  transition: 0.3s;
}

.featured-services .icon-box:hover .title a {
  color: var(--primary);
}

.featured-services .description {
  line-height: 24px;
  font-size: 14px;
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 28px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #5cb874;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  padding: 70px 20px 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
  box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  position: relative;
}

.services .icon-box .icon i {
  font-size: 36px;
  transition: 0.5s;
  position: relative;
}

.services .icon-box .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.services .icon-box .icon svg path {
  transition: 0.5s;
  fill: #f5f5f5;
}

.services .icon-box h4 {
  font-weight: 600;
  margin: 10px 0 15px 0;
  font-size: 22px;
}

.services .icon-box h4 a {
  color: #222222;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
}

.services .iconbox-blue i {
  color: #47aeff;
}

.services .iconbox-blue:hover .icon i {
  color: #fff;
}

.services .iconbox-blue:hover .icon path {
  fill: #47aeff;
}

.services .iconbox-orange i {
  color: #ffa76e;
}

.services .iconbox-orange:hover .icon i {
  color: #fff;
}

.services .iconbox-orange:hover .icon path {
  fill: #ffa76e;
}

.services .iconbox-pink i {
  color: #e80368;
}

.services .iconbox-pink:hover .icon i {
  color: #fff;
}

.services .iconbox-pink:hover .icon path {
  fill: #e80368;
}

.services .iconbox-yellow i {
  color: #ffbb2c;
}

.services .iconbox-yellow:hover .icon i {
  color: #fff;
}

.services .iconbox-yellow:hover .icon path {
  fill: #ffbb2c;
}

.services .iconbox-red i {
  color: #ff5828;
}

.services .iconbox-red:hover .icon i {
  color: #fff;
}

.services .iconbox-red:hover .icon path {
  fill: #ff5828;
}

.services .iconbox-teal i {
  color: #11dbcf;
}

.services .iconbox-teal:hover .icon i {
  color: #fff;
}

.services .iconbox-teal:hover .icon path {
  fill: #11dbcf;
}


.services .iconbox-violet i {
  color: var(--violet);
}

.services .iconbox-violet:hover .icon i {
  color: #fff;
}

.services .iconbox-violet:hover .icon path {
  fill: var(--violet);
}

.services .iconbox-vert i {
  color: #18d26e;
}

.services .iconbox-vert:hover .icon i {
  color: #fff;
}

.services .iconbox-vert:hover .icon path {
  fill: #18d26e;
}


.services .iconbox-rouge i {
  color: #d21821;
}

.services .iconbox-rouge:hover .icon i {
  color: #fff;
}

.services .iconbox-rouge:hover .icon path {
  fill: #d21821;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: var(--primary);
  padding: 80px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 4px;
}

.cta .cta-btn:hover {
  background: #fff;
  color: var(--primary);
}

@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}

@media (min-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}


/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

.footer {
  color: var(--primary);
  font-size: 14px;
}

.footer .footer-content {
  background:rgba(255, 255, 255, 0.788);
  padding: 60px 0 0 0;
}

.footer .footer-content .footer-info {
  margin-bottom: 30px;
}

.footer .footer-content .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

.footer .footer-content .footer-info h3 span {
  color: var(--primary);
}

.footer .footer-content .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}

.footer .footer-content h4 {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 15px;
}

.footer .footer-content h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  background: var(--primary);
  bottom: 0;
  left: 0;
}

.footer .footer-content .footer-links {
  margin-bottom: 30px;
}

.footer .footer-content .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-content .footer-links ul i {
  padding-right: 2px;
  color: var(--primary);
  font-size: 12px;
  line-height: 1;
}

.footer .footer-content .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-content .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-content .footer-links ul a {
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-content .footer-links ul a:hover {
  margin-left: 10px;
  background-color: var(--primary);
  color: #fff;
}

.footer .footer-content .footer-newsletter form {
  margin-top: 30px;
  background: var(--primary);
  padding: 2px;
  position: relative;
  border-radius: 4px;
}

.footer .footer-content .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

.footer .footer-content .footer-newsletter form input[type=email]:focus-visible {
  outline: none;
}

.footer .footer-content .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: var(--primary);
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.footer .footer-content .footer-newsletter form input[type=submit]:hover {
  transform: scale(1.1);
}

.footer .footer-legal {
  padding-top: 5px;
}

.footer .footer-legal .credits {
  padding-top: 4px;
  font-size: 13px;
  color: #ffffff;
}

.footer .footer-legal .credits a {
  color: var(--rose);
}

.footer .footer-legal .social-links a {
  font-size: 18px;
  display: inline-block;
  background: rgba(var(--rose), 0.1);
  color: var(--primary);
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.footer .footer-legal .social-links a:hover {
  background: var(--rose);
  text-decoration: none;
}


.line{
  line-height : 2em;
} 





/*---------------------------------
  Page A Propos
-----------------------------------*/

.a_propos .about-img {
  position: relative;
  margin: 60px 0 0 60px;
}

.a_propos .about-img:before {
  position: absolute;
  inset: -60px 0 0 -60px;
  z-index: -1;
  content: "";
  background: url("/public/assets/img/about-bg.png") top left;
  background-repeat: no-repeat;

}

@media (max-width: 575px) {
  .a_propos .about-img {
    margin: 30px 0 0 30px;
  }

  .a_propos .about-img:before {
    inset: -30px 0 0 -30px;
  }
}

.a_propos h3 {
  font-weight: 300;
  margin-bottom: 20px;
}

.a_propos h2 {
  /* font-weight:600; */
  color: var(--primary);
}

@media (max-width: 768px) {
  .a_propos h3 {
    font-size: 28px;
  }
}

.a_propos .nav-pills {
  border-bottom: 1px solid rgba(var(--primary),0.2);
}

.a_propos .nav-pills li+li {
  margin-left: 40px;
}

.a_propos .nav-link {
  color: #222222;
  background: none;
  font-size: 17px;
  font-weight: 400;
  padding: 12px 0;
  margin-bottom: -2px;
  border-radius: 0;
}

.a_propos .nav-link.active {
  color: var(--primary);
  background: none;
  border-bottom: 3px solid var(--primary);
}

@media (max-width: 575px) {
  .a_propos .nav-link {
    font-size: 16px;
  }
}

.a_propos .tab-content h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  color:var(--primary)
}

.a_propos .tab-content i {
  font-size: 22px;
  line-height: 0;
  margin-right: 8px;
  color: var(--primary)
}


.why_section {
  color: #fff;
  background: linear-gradient(to right, var(--primary), var(--violet));
}

.why_section .custom_heading-container h2 {
  border-color: #f9fcfb;
}

.why_section p {
  text-align: center;
  margin: 40px 0 55px 0;
}

.why_section .row .col-md-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.why_section .row .col-md-3 .img-box {
  width: 45px;
  margin-right: 15px;
}

.why_section .row .col-md-3 .img-box img {
  width: 100%;
}


@media (max-width: 768px) {
  
  .why_section .row .col-md-3 {
    margin: 15px auto;
    justify-content: flex-start;
    width: min-content;
  }

}






.img-fluid{
  padding-right: 20px;
}

.sub{
  font-size: 0.4em;
  margin-left: 50px;
  margin-top: 5px;
} 




/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/

.contact .map {
  margin-bottom: 40px;
}

.contact .map iframe {
  border: 0;
  width: 100%;
  height: 400px;
}

.contact .info {
  padding: 40px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.contact .info h3 {
  font-weight: 600;
  font-size: 24px;
}

.contact .info p {
  color:  black;
  margin-bottom: 30px;
  font-size: 15px;
}

.contact .info-item+.info-item {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(72, 86, 100, 0.15);
}

.contact .info-item i {
  font-size: 24px;
  color: var(--primary);
  transition: all 0.3s ease-in-out;
  margin-right: 20px;
}

.contact .info-item h4 {
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #485664;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: var(--primary);
}

.contact .php-email-form {
  width: 100%;
  background: #ffffff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #ffffff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #ffffff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #ffffff;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
  border-radius: 0px;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(#8f9fae);
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email] {
  height: 48px;
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
  height: 290px;
}

.contact .php-email-form button[type=submit] {
  background: var(--primary);
  border: 1px solid var(--primary);
  padding: 12px 50px;
  color: #fff;
  transition: 0.3s;
  border-radius: 4px;
  -webkit-border-radius: 2;
  -moz-border-radius: 2;
  -ms-border-radius: 2;
  -o-border-radius: 2;
}

.contact .php-email-form button[type=submit]:hover {
  border: 1px solid var(--primary);
  background: #fff;
  color: var(--primary);
}




/*----------------------
Page Heberment Web
-----------------------*/


/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
/* .pricing {
  background:#5067da54;
} */

.pricing .pricing-item {
  padding: 60px 40px;
  box-shadow: 0 3px 20px -2px #47474765;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: 2px solid var(--primary); */
  border-radius: 10px;
  overflow: hidden;
}

.pricing .pricing-header {
  background-color: var(--primary);
  text-align: center;
  padding: 40px;
  margin: -60px -40px 0;
}

.pricing h3 {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 30px;
  color: #fff;
}

.pricing h4 {
  font-size: 38px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
}

.pricing h4 span {
  font-size: 24px;
}

.pricing ul {
  padding: 30px 0;
  list-style: none;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding: 2px 0;
  display: flex;
  align-items: center;
}

.pricing ul i {
  color: #04d304;
  font-size: 40px;
  padding-right: 3px;
  line-height: 0;
}

.pricing ul .na i {
  color: red;
  font-size: 25px;
  padding-left: 4px;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .buy-btn {
  display: inline-block;
  padding: 8px 30px;
  border-radius: 4px;
  color: var(--primary);
  transition: none;
  font-size: 16px;
  font-weight: 700;
  transition: 0.3s;
  border: 1px solid var(--primary);
}

.pricing .buy-btn:hover {
  background: var(--primary);
  color: #fff;
}

.pricing .featured {
  border: 3px solid var(--primary);
}

.pricing .featured .buy-btn {
  background: var(--primary);
  color: #fff;
}

.pricing .pricing-item:hover {
  transition:all ease-in-out 0.4s;
  transform:scale(1.02);
  
}

/* -------VPS ------*/

.vps {
  padding: 0;
  margin-bottom: 60px;
}

.vps .container {
  padding: 80px;
  background: #5067da2d;
  border-radius: 15px;
}

@media (max-width: 992px) {
  .vps .container {
    padding: 60px;
  }
}

.vps .content h3 {
  color: var(--primary);
  font-size: 27px;
  font-weight: 700;
}

.vps .content h3 em {
  font-style: normal;
  position: relative;
}

.vps .content h3 em:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  height: 5px;
  background: var(--primary);
  z-index: -1;
}

.vps .content p {
  padding-top: 10px;
  font-size: 16px;
}

.vps .content .vps-btn {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 4px;
  transition: all ease-in-out 0.3s;
  margin-top: 10px;
  background: var(--primary)
}

.vps .content .vps-btn:hover {
  transform: scale(1.01);
}

.vps .img {
  position: relative;
}

/* .vps .img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(38, 26, 192, 0.13);
  border-radius: 15px;
  transform: rotate(12deg);
} */

.vps .img:after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(21, 55, 209, 0.178);
  border-radius: 15px;
  transform: rotate(6deg);
}

.vps .img img {
  position: relative;
  z-index: 3;
  border-radius: 15px;
}

/*---------------------------
  FIN PAGE HEBERGEMENT WEB
---------------------------/*


/*---------------------------
  PAGE SITE INTERNET 
---------------------------*/

/** We Do section **/

.we_do {
  padding-top: 70px;
}

.we_do .titlepage {
  text-align: center;
  padding-bottom: 60px;
}

.we_do .titlepage p {
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
  padding-top: 10px;
  color: #070101;
}

.we_do .we_box {
  padding: 30px 30px 60px 30px;
  text-align: center;
  border: var(--primary) solid 1px;
  border-radius: 10px;
  transition: ease-in all 0.3s;
  margin-bottom: 30px;
  height: 500px;
}

.we_do .we_box span {
  font-weight: bold;
  font-size: 30px;
  color: #000000;
  border: var(--primary) solid 2px;
  width: 65px;
  height: 65px;
  display: block;
  margin: 0 auto;
  line-height: 67px;
  border-radius: 44px;
  background-color: #fff;
  transition: ease-in all 0.5s;
}

.we_do .we_box h3 {
  padding: 30px 0px 20px 0px;
  color: #070101;
  font-size: 25px;
  line-height: 30px;
  font-weight: bold;
  transition: ease-in all 0.5s;
}

.we_do .we_box p {
  font-size: 17px;
  color: #010103;
  line-height: 32px;
  transition: ease-in all 0.5s;
}

#hover_color:hover.we_box {
  background-color: var(--primary);
  transition: ease-in all 0.5s;
}

#hover_color:hover.we_box span {
  color: var(--primary);
  transition: ease-in all 0.5s;
}

#hover_color:hover.we_box h3 {
  color: #fff;
  transition: ease-in all 0.5s;
}

#hover_color:hover.we_box p {
  color: #fff;
  transition: ease-in all 0.5s;
}

.we_do .read_more {
  margin-top: 40px !important;
  margin: 0 auto;
  display: block;
  background-color: #070101;
  color: #fff;
}

.we_do .read_more:hover {
  background-color: #fcc10e;
}


/** end We Do section **/

/****** FAQ ********/

@media (max-width: 991px) {
  .faq {
    padding: 0;
  }
}

.faq .content h3 {
  font-weight: 400;
  font-size: 30px;
  color: var(--primary);
}

.faq .content h4 {
  font-size: 20px;
  margin-top: 5px;
}

/* .faq .content p {
  font-size: 15px;
  color: red;
} */

.faq .img {
  /* background-image: url("../img/faq.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
}

.faq .accordion-item {
  border: 0;
  margin-top: 15px;
  box-shadow: 0px 5px 25px 0px rgba(128, 128, 128, 0.205);
}

.faq .accordion-collapse {
  border: 0;
}

.faq .accordion-button {
  padding: 15px 40px 20px 60px;
  font-weight: 600;
  border: 0;
  text-align: left;
  box-shadow: none;
  border-radius: 5px;
}

.faq .accordion-button:not(.collapsed) {
  color: var(--primary);
  border-bottom: 0;
  box-shadow: none;
}

.faq .question-icon {
  position: absolute;
  top: 14px;
  left: 25px;
  font-size: 20px;
  color: var(--primary);
}

.faq .accordion-button:after {
  position: absolute;
  right: 15px;
  top: 15px;
}

.faq .accordion-body {
  padding: 10px 30px 25px 60px;
  border: 0;
  border-radius: 5px;
  background: var(--color-white);
  box-shadow: none;
}





/******* EN FAQ *****/










/*--------------------------
FIN PAGE SITE INTERNET
---------------------------*/






/* Services */

.services .img {
  border-radius: 8px;
  overflow: hidden;
}

.services .img img {
  transition: 0.6s;
}

.services .details {
  padding: 50px 30px;
  margin: -100px 30px 0 30px;
  transition: all ease-in-out 0.3s;
  background: #ffffff;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.1);
}

.services .details .icon {
  margin: 0;
  width: 72px;
  height: 72px;
  background: var(--primary);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 28px;
  transition: ease-in-out 0.3s;
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  border: 6px solid #ffffff;
}

.services .details h3 {
  color: 26, 31, 36;
  font-weight: 700;
  margin: 10px 0 15px 0;
  font-size: 22px;
  transition: ease-in-out 0.3s;
}

.services .details p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}


.services .service-item:hover .details h3 {
  color: var(--primary);
}

.services .service-item:hover .details .icon {
  background: #ffffff;
  border: 2px solid var(--primary);
}

.services .service-item:hover .details .icon i {
  color: var(--primary);
}

.services .service-item:hover .img img {
  transform: scale(1.2);
}

/* Description des solutions  */

.breadcrumbs {
  padding: 15px 0;
  background: #f4f6f7;
  min-height: 40px;
  /* margin-top: 70px; */
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 600;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4c5c63;
  content: "/";
}


/* .portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #009cea;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #009cea;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(54, 65, 70, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
} */





/*-------------------------
      FORMATIONS
--------------------------*/

.features {
  position: relative;
  overflow: hidden;
}



.features::before {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 500px;
  content: " ";
}

.features::after {
  position: absolute;
  z-index: 50;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 1080px;
  content: " ";
  opacity: 1;
  background-position: 80px -75px;
}

.features__wrapper {
  position: relative;
  z-index: 200;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  /* padding-top: 160px; */
  /* padding-bottom: 200px; */
}

.features__elements-wrapper {
  margin-top: 5px;
}

.features__elements-wrapper::after {
  display: table;
  content: " ";
  clear: both;
}

.features__element {
  display: inline-block;
  width: 49.9%;
  float: left;
  height: 100px;
  margin-top: 80px;
  transition: 0.3s ease-in-out;
  /* cursor: pointer; */
  cursor: default;
}

/* .features__element:hover{
  background-color: #5067da21;
  border-radius: 12px;
  transform: scale(1.03);
} */

.features__image {
  background-size: 100px 100px;
  background-repeat: no-repeat;
  background-position: top center;
  width: 25%;
  min-height: 100px;
  height: 100%;
  float: left;
}


.features__text {
  float: left;
  width: 65%;
}

.features__title {
  font-size: 20px;
  line-height: 1;
  padding-bottom: 3px;
  font-weight: 600;
  color: var(--primary);
}

.features__description {
  margin-top: 2px;
  /* font-size: 15px; */
  font-weight: 300;
  letter-spacing: 0.3px;
  line-height: 1.3;
  /* color: rgba(255, 255, 255, 0.7); */
}

.section-header__title--features{
  color: var(--primary);
  font-size: 25px;
  text-transform: capitalize;
  font-weight: 600;
  padding-bottom: 5px;
}

@media (max-width: 1300px) {
  
  .features__elements-wrapper {
    margin-top: 50px;
  }
  .features__element {
    width: 45.9%;
    /* margin-top: 40px; */
    margin-left: 2%;
    height: 160px;
  }
  .features__image {
    background-size: 90px 90px;
  }

}

/*------------------------------------*	(max-width: 1050px)
\*------------------------------------*/
@media (max-width: 1050px) {
  
  .features__wrapper {
    padding-left: 20px;
    padding-right: 20px;
    /* padding-top: 80px; */
    /* padding-bottom: 100px; */
  }
}

/*------------------------------------*	(max-width: 960px)
\*------------------------------------*/
@media (max-width: 960px) {
  .features__element {
    display: block;
    width: 100%;
    margin-top: 50px;
    height: auto;
  }

  .section-header__subtitle {
    padding-left: 60px;
  }
}
/*------------------------------------*	(max-width: 500px)
\*------------------------------------*/
@media (max-width: 500px) {
  .experience__icons-wrapper {
    padding-left: 5%;
    padding-right: 5%;
  }
  .features__image {
    background-size: contain;
    margin-right: 10px;
  }
  .section-header__title {
    font-size: 30px;
    background-size: 32px 32px;
    padding-left: 50px;
  }
  .section-header__subtitle {
    font-size: 20px;
    padding-left: 20px;
  }
}


/*------------------------------------*	(max-width: 400px)
\*------------------------------------*/
@media (max-width: 400px) {
  .features__title {
    font-size: 20px;
  }
}



/*--------------------------------------------------------------
# PAGE REALISATIONS
--------------------------------------------------------------*/
.work-box {
  margin-bottom: 3rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* background-color: #fff; */
}

.work-box:hover img {
  transform: scale(1.06);
}

.work-img {
  display: block;
  overflow: hidden;
}

.work-img img {
  transition: all 0.5s ease-in-out;
  padding-right: 0;
}

.work-content {
  padding: 2rem 3% 1rem 4%;
  background: #5067da1e;
}

.work-content .w-more {
  color: #4e4e4e;
  font-size: 0.8rem;
}

.work-content .w-more .w-ctegory {
  color: var(--primary);
}

.work-content .w-like {
  font-size: 2.5rem;
  color: var(--primary);
  float: right;
}

.work-content .w-like:hover{
  transition: all ease-out 0.3s;
  transform: rotate(360deg);
}

.work-content .w-like a {
  color: var(--primary);
}

.work-content .w-like .num-like {
  font-size: 0.7rem;
}

.w-title {
  font-size: 1.2rem;
}



.projet div{
  width:500px;
  padding-right: 10px;
}
.boite{
  max-height: 500px;
}




























